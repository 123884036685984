<template>
    <component :is="componentMap[as]" :method="action ? action : ''" :href="href"  type="button" as="button" :class="[
        `transition-all duration-500 ease-in-out ${buttons[variant][size]}`,
        disabled ? 'opacity-35 cursor-not-allowed' : ''
    ]" :disabled="disabled">
        <slot />
    </component>
</template>

<script setup>
    import {Link} from "@inertiajs/vue3";

    const props = defineProps({
        as: {
            type: String,
            default: 'button',
        },
        action: {
            type: String,
            default: null,
            required: false,
        },
        href: {
            type: String,
            required: false,
            default: null,
        },
        size: {
            type: String,
            default: "md"
        },
        variant: {
            type: String,
            default: "primary"
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        }
    });

    const buttons = {
        primary: {
            xs: "rounded bg-yellow-400 px-2 py-1 text-xs font-semibold text-gray-900 hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600",
            sm: "rounded bg-yellow-400 px-2 py-1 text-sm font-semibold text-gray-900 hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600",
            md: "rounded bg-yellow-400 px-2.5 py-1.5 text-sm font-semibold text-gray-900 hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600",
            lg: "rounded bg-yellow-400 px-3 py-2 text-sm font-semibold text-gray-900 hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600",
            xl: "rounded bg-yellow-400 px-3.5 py-2.5 text-sm font-semibold text-gray-900 hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600",
        },
        danger: {
            xs: "rounded bg-red-600 px-2 py-1 text-xs font-semibold text-white hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600",
            sm: "rounded bg-red-600 px-2 py-1 text-sm font-semibold text-white hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600",
            md: "rounded bg-red-600 px-2.5 py-1.5 text-sm font-semibold text-white hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600",
            lg: "rounded bg-red-600 px-3 py-2 text-sm font-semibold text-white hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600",
            xl: "rounded bg-red-600 px-3.5 py-2.5 text-sm font-semibold text-white hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600",
        },
        soft: {
            xs: "rounded bg-yellow-50 px-2 py-1 text-xs font-semibold text-yellow-900 hover:bg-yellow-100",
            sm: "rounded bg-yellow-50 px-2 py-1 text-sm font-semibold text-yellow-900 hover:bg-yellow-100",
            md: "rounded bg-yellow-50 px-2.5 py-1.5 text-sm font-semibold text-yellow-900 hover:bg-yellow-100",
            lg: "rounded bg-yellow-50 px-3 py-2 text-sm font-semibold text-yellow-900 hover:bg-yellow-100",
            xl: "rounded bg-yellow-50 px-3.5 py-2.5 text-sm font-semibold text-yellow-900 hover:bg-yellow-100",
        },
        ghost: {
            xs: "rounded bg-transparent px-2 py-1 text-xs font-semibold text-gray-900 hover:bg-gray-100",
            sm: "rounded bg-transparent px-2 py-1 text-sm font-semibold text-gray-900 hover:bg-gray-100",
            md: "rounded bg-transparent px-2.5 py-1.5 text-sm font-semibold text-gray-900 hover:bg-gray-100",
            lg: "rounded bg-transparent px-3 py-2 text-sm font-semibold text-gray-900 hover:bg-gray-100",
            xl: "rounded bg-transparent px-3.5 py-2.5 text-sm font-semibold text-gray-900 hover:bg-gray-100",
        },
        outline: {
            xs: "border border-gray-300 rounded bg-transparent px-2 py-1 text-xs font-semibold text-gray-900 hover:bg-gray-100",
            sm: "border border-gray-300 rounded bg-transparent px-2 py-1 text-sm font-semibold text-gray-900 hover:bg-gray-100",
            md: "border border-gray-300 rounded bg-transparent px-2.5 py-1.5 text-sm font-semibold text-gray-900 hover:bg-gray-100",
            lg: "border border-gray-300 rounded bg-transparent px-3 py-2 text-sm font-semibold text-gray-900 hover:bg-gray-100",
            xl: "border border-gray-300 rounded bg-transparent px-3.5 py-2.5 text-sm font-semibold text-gray-900 hover:bg-gray-100",
        }
    };

    const componentMap = {
        button: 'button',
        link: Link,
        external: 'a'
    };
</script>
