<template>
  <span :class="[
      `transition duration-300 ease-in-out`,
      variantColors[variant][color],
      inactive ? `opacity-40 hover:opacity-100 hover:text-opacity-60` : ``,
  ]">
    <slot>Badge</slot>
    <button v-if="closable" type="button" class="group relative -mr-1 h-3.5 w-3.5 rounded-sm" :class="hoverColors[color]" @click="handleClose">
      <span class="sr-only">Remove</span>
      <svg viewBox="0 0 14 14" class="h-3.5 w-3.5" :class="strokeColors[color]">
        <path d="M4 4l6 6m0-6l-6 6" />
      </svg>
      <span class="absolute -inset-1" />
    </button>
  </span>
</template>

<script setup>
const props = defineProps({
    color: {
        type: String,
        required: true,
    },
    variant: {
        type: String,
        required: false,
        default: 'default',
    },
    closable: {
        type: Boolean,
        required: false,
        default: false,
    },
    inactive: {
        type: Boolean,
        required: false,
        default: false,
    }
});

const emit = defineEmits(['close']);

const handleClose = () => {
    emit('close');
};

const variantColors = {
    default: {
        gray: "inline-flex items-center gap-x-1.5 rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10",
        red: "inline-flex items-center gap-x-1.5 rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10",
        rose: "inline-flex items-center gap-x-1.5 rounded-md bg-rose-50 px-2 py-1 text-xs font-medium text-rose-700 ring-1 ring-inset ring-rose-700/10",
        yellow: "inline-flex items-center gap-x-1.5 rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20",
        green: "inline-flex items-center gap-x-1.5 rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20",
        teal: "inline-flex items-center gap-x-1.5 rounded-md bg-teal-50 px-2 py-1 text-xs font-medium text-teal-700 ring-1 ring-inset ring-teal-600/20",
        cyan: "inline-flex items-center gap-x-1.5 rounded-md bg-cyan-50 px-2 py-1 text-xs font-medium text-cyan-700 ring-1 ring-inset ring-cyan-700/10",
        sky: "inline-flex items-center gap-x-1.5 rounded-md bg-sky-50 px-2 py-1 text-xs font-medium text-sky-700 ring-1 ring-inset ring-sky-700/10",
        blue: "inline-flex items-center gap-x-1.5 rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10",
        indigo: "inline-flex items-center gap-x-1.5 rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10",
        purple: "inline-flex items-center gap-x-1.5 rounded-md bg-purple-50 px-2 py-1 text-xs font-medium text-purple-700 ring-1 ring-inset ring-purple-700/10",
        fuchsia: "inline-flex items-center gap-x-1.5 rounded-md bg-fuchsia-50 px-2 py-1 text-xs font-medium text-fuchsia-700 ring-1 ring-inset ring-fuchsia-700/10",
        pink: "inline-flex items-center gap-x-1.5 rounded-md bg-pink-50 px-2 py-1 text-xs font-medium text-pink-700 ring-1 ring-inset ring-pink-700/10",
    },
    outline: {
        teal: "inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-teal-700 ring-1 ring-inset ring-gray-200 hover:bg-teal-50 hover:text-teal-500",
        cyan: "inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-cyan-700 ring-1 ring-inset ring-gray-200 hover:bg-cyan-50 hover:text-cyan-500",
        fuchsia: "inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-fuchsia-700 ring-1 ring-inset ring-gray-200 hover:bg-fuchsia-50 hover:text-fuchsia-500",
        pink: "inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-pink-700 ring-1 ring-inset ring-gray-200 hover:bg-pink-50 hover:text-pink-500",
    }
};

const hoverColors = {
    gray: "hover:bg-gray-500/20",
    red: "hover:bg-red-600/20",
    yellow: "hover:bg-yellow-600/20",
    green: "hover:bg-green-600/20",
    blue: "hover:bg-blue-600/20",
    indigo: "hover:bg-indigo-600/20",
    purple: "hover:bg-purple-600/20",
    pink: "hover:bg-pink-600/20",
};

const strokeColors = {
    gray: "stroke-gray-600/50 group-hover:stroke-gray-600/75",
    red: "stroke-red-600/50 group-hover:stroke-red-600/75",
    yellow: "stroke-yellow-700/50 group-hover:stroke-yellow-700/75",
    green: "stroke-green-700/50 group-hover:stroke-green-700/75",
    blue: "stroke-blue-700/50 group-hover:stroke-blue-700/75",
    indigo: "stroke-indigo-600/50 group-hover:stroke-indigo-600/75",
    purple: "stroke-violet-600/50 group-hover:stroke-violet-600/75",
    pink: "stroke-pink-700/50 group-hover:stroke-pink-700/75",
};
</script>
