<template>
    <component :is="size" :class="[
        `${sizes[size]}`,
        `font-heading font-bold tracking-tight text-gray-800`
    ]">
        <slot />
    </component>
</template>

<script setup>
const props = defineProps({
    size: {
        type: String,
        default: 'h1'
    }
});

const sizes = {
    'h1': 'text-3xl',
    'h2': 'text-2xl',
    'h3': 'text-xl',
};
</script>
