<template>
    <Card class="px-6">
        <Heading size="h3" class="mb-2.5">
            Popular Tags
        </Heading>
        <div class="flex flex-wrap gap-x-1.5 gap-y-1">
            <Link :href="addTag(tag.type, tag.id)"
               v-for="tag in popularTags" :key="`tag_${tag.id}_${url}`"
            >
                <Badge :color="`${COLORS[tag.type]}`">
                    {{ tag.name.toLowerCase() }}
                </Badge>
            </Link>
        </div>
    </Card>
</template>

<script setup>
import Card from "@/Components/Card.vue";
import {usePage, Link} from "@inertiajs/vue3";
import {computed} from "vue";
import Heading from "@/Components/Heading.vue";
import Badge from "@/Components/Badge.vue";
import {addTag} from "@/addTag.js";

const COLORS = {
    "types": "cyan",
    "categories": "fuchsia",
    "compensation": "teal",
    "skills": "pink",
}

const page = usePage();
const popularTags = computed(() => page.props.sidebar.popular_tags);
const url = computed(() => page.url);
</script>
