<template>
    <div class="sticky top-0 z-50">
        <div v-if="$page.props.flash.success && isOpen.success">
            <div class="flex items-start justify-between bg-green-50 p-4 border-b border-green-100">
                <div class="flex">
                    <div class="flex-shrink-0">
                        <CheckCircleIcon class="h-5 w-5 text-green-400" aria-hidden="true" />
                    </div>
                    <div class="ml-3">
                        <div class="text-sm text-green-700">
                            <p>{{ $page.props.flash.success }}</p>
                        </div>
                    </div>
                </div>
                <button @click="close('success')" class="text-green-500 hover:text-green-700 ml-3 transition-colors duration-300 ease-in-out">
                    <span class="sr-only">Close</span>
                    <XMarkIcon class="h-5 w-5" />
                </button>
            </div>
        </div>
        <div v-if="$page.props.flash.message && isOpen.message">
            <div class="flex items-start justify-between bg-blue-50 p-4 border-b border-blue-100">
                <div class="flex">
                    <div class="flex-shrink-0">
                        <InformationCircleIcon class="h-5 w-5 text-blue-400" aria-hidden="true" />
                    </div>
                    <div class="ml-3">
                        <div class="text-sm text-blue-700">
                            <p>{{ $page.props.flash.message }}</p>
                        </div>
                    </div>
                </div>
                <button @click="close('message')" class="text-blue-400 hover:text-blue-700 ml-3 transition-colors duration-300 ease-in-out">
                    <span class="sr-only">Close</span>
                    <XMarkIcon class="h-5 w-5" />
                </button>
            </div>
        </div>
        <div v-if="$page.props.flash.error && isOpen.error">
            <div class="bg-white sticky top-0 w-full z-50">
                <div class="flex items-start justify-between bg-red-50 p-4 border-b border-red-100">
                    <div class="flex">
                        <div class="flex-shrink-0">
                            <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
                        </div>
                        <div class="ml-3">
                            <div class="text-sm text-red-700">
                                <p>{{ $page.props.flash.error }}</p>
                            </div>
                        </div>
                    </div>
                    <button @click="close('error')" class="text-red-400 hover:text-red-700 ml-3 transition-colors duration-300 ease-in-out">
                        <span class="sr-only">Close</span>
                        <XMarkIcon class="h-5 w-5" />
                    </button>
                </div>
            </div>
        </div>
        <div v-if="$page.props.flash.warning && isOpen.warning">
            <div class="flex items-start justify-between bg-yellow-50 p-4 border-b border-yellow-100">
                <div class="flex">
                    <div class="flex-shrink-0">
                        <ExclamationTriangleIcon class="h-5 w-5 text-yellow-400" aria-hidden="true" />
                    </div>
                    <div class="ml-3">
                        <div class="text-sm text-yellow-700">
                            <p>{{ $page.props.flash.warning }}</p>
                        </div>
                    </div>
                </div>
                <button @click="close('warning')" class="text-yellow-500 hover:text-yellow-700 ml-3 transition-colors duration-300 ease-in-out">
                    <span class="sr-only">Close</span>
                    <XMarkIcon class="h-5 w-5" />
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
import {
    XCircleIcon,
    ExclamationTriangleIcon,
    CheckCircleIcon,
    InformationCircleIcon,
    XMarkIcon,
} from "@heroicons/vue/24/solid/index.js";
import {ref} from "vue";

const isOpen = ref({
    success: true,
    message: true,
    error: true,
    warning: true,
});

const close = (type) => {
    isOpen.value[type] = false;
};

</script>
