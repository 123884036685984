<template>
    <Card class="px-0">
        <Heading size="h3" class="px-6 mb-2.5">
            Latest Members
        </Heading>
        <div class="flex flex-col">
            <Link :href="route('user.show', {user: user.username})"
                v-for="user in latestMembers" :key="`latest_member_${user.username}`"
                class="flex gap-x-3 py-2.5 px-6 items-center group hover:bg-yellow-50 transition duration-300 ease-in-out"
            >
                <img class="h-9 w-9 rounded-md" :src="user.avatar" :alt="`${user.name}'s Avatar`">
                <div>
                    <div class="text-gray-700 font-semibold text-sm group-hover:text-yellow-900 transition duration-300 ease-in-out">
                        {{user.name}}
                    </div>
                    <div class="text-gray-500 text-xs group-hover:text-yellow-700 transition duration-300 ease-in-out">
                        @{{user.username}}
                    </div>
                </div>
            </Link>
        </div>
    </Card>
</template>

<script setup>

import Card from "@/Components/Card.vue";
import {usePage, Link} from "@inertiajs/vue3";
import {computed} from "vue";
import Heading from "@/Components/Heading.vue";

const page = usePage();
const latestMembers = computed(() => page.props.sidebar.latest_members);
</script>
