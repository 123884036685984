import {ref} from "vue";

export const isVisible = ref(false);
export const text = ref(null);

export const onSuccess = ref(() => {});

export const useConfirm = (_onSuccess, _text) => () => {
    isVisible.value = true;
    onSuccess.value = _onSuccess;
    text.value = _text;
};
