<template>
    <Head :title="title ? `${title} - ${appName}` : appName">
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png">
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png">
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png">
        <link rel="manifest" href="/site.webmanifest">

        <meta head-key="robots" name="robots" content="index, follow">

        <meta head-key="description" name="description" :content="description">
        <meta head-key="og:description" property="og:description" :content="description" />
        <meta head-key="twitter:description" name="twitter:description" :content="description">

        <meta head-key="og:site_name" property="og:site_name" :content="appName" />

        <meta v-if="imageUrl" head-key="og:image" property="og:image" :content="imageUrl" />
        <meta v-if="imageHeight" head-key="og:image:height" property="og:image:height" :content="imageHeight">
        <meta v-if="imageWidth" head-key="og:image:width" property="og:image:width" :content="imageWidth">

        <meta head-key="twitter:card" name="twitter:card" content="summary_large_image">
        <meta head-key="twitter:title" name="twitter:title" :content="title ? `${title} - ${appName}` : appName">
        <meta head-key="twitter:description" name="twitter:description" :content="description">
        <meta v-if="imageUrl" head-key="twitter:image" name="twitter:image" :content=imageUrl>

        <meta head-key="twitter:site" name="twitter:site" :content="appTwitterHandle">

        <meta head-key="viewport" name="viewport" content="width=device-width, initial-scale=1">

        <meta v-if="url" head-key="og:url" property="og:url" :content="url" />
        <link v-if="url" head-key="canonical" rel="canonical" :href="url"> />
        <slot :appName="appName" />
    </Head>
</template>

<script setup>
import { Head } from '@inertiajs/vue3'

const appName = import.meta.env.VITE_APP_NAME || 'HackSpace';
const appTwitterHandle = import.meta.env.VITE_APP_TWITTER_HANDLE || '@HackSpaceHQ';

defineProps({
    title: {
        type: [String, null],
        default: null,
    },
    description: {
        type: String,
        default: 'Join HackSpace, the ultimate community for developers, designers, and creative minds. Connect with like-minded individuals to collaborate on side projects, share ideas, and bring your passions to life.'
    },
    url: {
        type: [String, null],
        required: false,
        default: null
    },
    imageUrl: {
        type: [String, null],
        required: false,
        default: null,
    },
    imageHeight: {
        type: [Number, null],
        required: false,
        default: null
    },
    imageWidth: {
        type: [Number, null],
        require: false,
        default: null
    }
})
</script>
