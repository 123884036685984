<template>
    <div class="mx-auto max-w-7xl flex w-full items-start divide-x divide-gray-200 min-h-svh">
        <AppHead>
            <meta head-key="og:type" property="og:type" content="website"/>
        </AppHead>

        <!-- Static sidebar for desktop -->
        <aside class="sticky top-0 hidden w-64 shrink-0 lg:block h-svh">
            <!-- Sidebar component, swap this element with another sidebar if you like -->
            <div class="flex grow flex-col gap-y-5 overflow-y-auto h-full bg-white px-6 pt-8">
                <div class="flex shrink-0">
                    <Link :href="route('index')">
                        <Logo class="h-10 fill-yellow-950 -mx-2"/>
                    </Link>
                </div>
                <nav class="flex flex-1 flex-col gap-y-4">
                    <div v-if="user" class="-mx-2">
                        <Button :href="route('listing.create')" as="link" class="w-full flex gap-x-3 items-center leading-6 py-2" size="sm">
                            <PlusIcon class="h-6 w-6 shrink-0" /> Create Listing
                        </Button>
                    </div>

                    <ul v-if="!user" role="list" class="flex flex-1 flex-col gap-y-7">
                        <li>
                            <ul role="list" class="-mx-2 space-y-1">
                                <li v-for="item in navigation" :key="`guest_sidebar_nav_${item.name}`">
                                    <Link :preserve-state="false" as="button" :method="item.method ?? 'GET'" :href="item.href"
                                          :class="[item.current ? 'bg-yellow-50 text-yellow-900' : 'text-gray-900 hover:bg-yellow-50 hover:text-yellow-900 transition-all duration-500 ease-in-out', 'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 w-full']">
                                        <component :is="item.icon"
                                                   :class="[item.current ? 'text-yellow-900' : 'text-gray-900 group-hover:text-yellow-900 transition-all duration-500 ease-in-out', 'h-6 w-6 shrink-0']"
                                                   aria-hidden="true"/>
                                        {{ item.name }}
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <li class="-mx-2 mt-auto mb-4">
                            <div class="-mx-2 flex flex-col gap-y-2">
                                <Button @click="openLogInModal()" class="w-full" size="xl" variant="ghost">
                                    Log In
                                </Button>
                                <Button @click="openLogInModal()" class="w-full" size="xl">
                                    Sign Up
                                </Button>
                            </div>
                        </li>
                    </ul>

                    <ul v-if="user" role="list" class="flex flex-1 flex-col gap-y-7">
                        <li>
                            <ul role="list" class="-mx-2 space-y-1">
                                <li v-for="item in navigation.filter((_item) => !_item.mobileOnly)" :key="`member_sidebar_nav_${item.name}`">
                                    <Link :preserve-state="false" as="button" :method="item.method ?? 'GET'" :href="item.href"
                                          :class="[item.current ? 'bg-yellow-50 text-yellow-900' : 'text-gray-900 hover:bg-yellow-50 hover:text-yellow-900 transition-all duration-500 ease-in-out', 'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 w-full']">
                                        <component :is="item.icon"
                                                   :class="[item.current ? 'text-yellow-900' : 'text-gray-900 group-hover:text-yellow-900 transition-all duration-500 ease-in-out', 'h-6 w-6 shrink-0']"
                                                   aria-hidden="true"/>
                                        {{ item.name }}
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <li class="-mx-2 mt-auto mb-4">
                            <div class="flex gap-x-1.5">
                                <img class="h-9 w-9 rounded-md" :src="user.avatar" alt=""/>
                                <div class="flex flex-col leading-tight text-sm justify-center">
                                    <div class="font-semibold">
                                        {{ user.name }}
                                    </div>
                                    <div class="text-gray-700">
                                        @{{ user.username }}
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </nav>
            </div>
        </aside>

        <main class="flex-1 flex-grow w-full min-h-svh relative">
             <FlashMessages />
            <div class="py-4 md:py-8">
                <Heading v-if="slots.heading" class="border-b border-gray-200 pb-4 px-6 leading-none flex items-center gap-x-1.5">
                    <Button variant="ghost" @click="back">
                        <ArrowLeftIcon class="h-6 w-6 text-gray-900" />
                    </Button>
                    <slot name="heading" />
                </Heading>
                <slot/>
            </div>
        </main>

        <aside class="sticky top-0 hidden w-80 shrink-0 xl:block py-8 px-4 h-svh overflow-y-scroll no-scrollbar">
            <div class="flex flex-col gap-y-4">
                <Card class="border-yellow-500 bg-yellow-50 text-yellow-900 px-6">
                    <h2 class="text-2xl font-bold mb-2">Welcome! 👋</h2>
                    <p>
                        We're in alpha and would love your feedback. Got ideas or just want to chat?<br> <Link class="underline decoration-1 decoration-yellow-900" :href="route('contact')">Reach out anytime!</Link>
                    </p>
                </Card>
                <LatestMembers />
                <PopularTags />
                <div class="text-gray-600 text-sm">
                    <ul class="space-y-1">
<!--                        <li>-->
<!--                            <Link class="transition duration-300 ease-in-out hover:text-yellow-900" :href="route('about')">About</Link>-->
<!--                        </li>-->
                        <li>
                            <Link class="transition duration-300 ease-in-out hover:text-yellow-900" :href="route('contact')">Contact</Link>
                        </li>
                        <li>
                            <Link class="transition duration-300 ease-in-out hover:text-yellow-900" :href="route('terms')">Terms & Conditions</Link>
                        </li>
                        <li>
                            <Link class="transition duration-300 ease-in-out hover:text-yellow-900" :href="route('privacy')">Privacy Policy</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </aside>
    </div>
    <div v-if="user"
         class="sticky bottom-0 z-40 flex items-center bg-white px-4 py-2.5 shadow-sm sm:px-6 lg:hidden border-t border-gray-200">
        <ul role="list" class="space-y-1 flex items-center justify-between gap-x-6 w-full">
            <li class="flex-none">
                <Link :href="route('user.show', {user: user.username})">
                    <span class="sr-only">Your profile</span>
                    <img class="h-8 w-8 rounded-md bg-yellow-50" :src="user.avatar"
                         alt=""/>
                </Link>
            </li>
            <template v-for="item in navigation.filter((_item) => !_item.desktopOnly)" :key="`mobile_user_nav_${item.name}`">
                <li v-if="item.mobile !== false">
                    <Link as="button" :method="item.method ?? 'GET'" :href="item.href"
                          :class="[item.current ? 'bg-yellow-50 text-yellow-900' : 'text-gray-900 hover:bg-yellow-50 hover:text-yellow-900 transition-all duration-500 ease-in-out', 'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 w-full']">
                        <component :is="item.icon"
                                   :class="[item.current ? 'text-yellow-900' : 'text-gray-900 group-hover:text-yellow-900 transition-all duration-500 ease-in-out', 'h-6 w-6 shrink-0']"
                                   aria-hidden="true"/>
                    </Link>
                </li>
            </template>
            <li>
                <Menu as="div" class="relative inline-block text-left" v-slot="{ open }">
                    <MenuButton type="button"
                            :class="[open ? 'bg-yellow-50 text-yellow-900' : 'text-gray-900 transition-all duration-500 ease-in-out', 'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 w-full']"
                            >
                        <span class="sr-only">Open sidebar</span>
                        <EllipsisVerticalIcon class="h-6 w-6" aria-hidden="true"/>
                    </MenuButton>

                    <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                        <MenuItems class="-top-2 transform -translate-y-full absolute right-0 w-56 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div class="py-1">
                                <MenuItem v-slot="{ active }">
                                    <Link as="button" method="POST" :href="route('logout')" :class="[active ? 'bg-yellow-50 text-yellow-900' : 'text-gray-700', 'px-4 py-2 text-sm flex items-center w-full']">
                                        <ArrowLeftStartOnRectangleIcon class="h-5 w-5 mr-1.5" /> Sign Out
                                    </Link>
                                </MenuItem>
                            </div>
                        </MenuItems>
                    </transition>
                </Menu>
            </li>
        </ul>
    </div>
    <div v-if="!user"  class="sticky bottom-0 z-40 flex items-center justify-between gap-x-6 bg-white px-4 py-2.5 shadow-sm sm:px-6 lg:hidden border-t border-gray-200">
        <Button @click="openLogInModal()" class="w-full" size="xl" variant="ghost">
            Log In
        </Button>
        <Button @click="openLogInModal()" class="w-full" size="xl">
            Sign Up
        </Button>
    </div>
    <Confirm />
    <LogIn :is-open="isOpen" @close="() => closeLogInModal()"/>
</template>

<script setup>
import {computed, ref, useSlots} from 'vue'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'
import {
    ArrowLeftStartOnRectangleIcon,
    CogIcon,
    HomeIcon,
    EllipsisVerticalIcon,
    ArrowLeftIcon, PlusIcon, UserIcon,
} from '@heroicons/vue/24/outline'
import Card from "@/Components/Card.vue";
import {Link, router, usePage} from "@inertiajs/vue3";
import Button from "@/Components/Button.vue";
import AppHead from "@/Components/AppHead.vue";
import Logo from "@/Components/Logo.vue";
import LogIn from "@/Components/LogIn.vue";
import Confirm from "@/Components/Confirm/Confirm.vue";
import Heading from "@/Components/Heading.vue";
import LatestMembers from "@/Components/LatestMembers.vue";
import PopularTags from "@/Components/PopularTags.vue";
import FlashMessages from "@/Components/FlashMessages.vue";

defineProps({
    heading: {
        type: [String, null],
        default: null,
    }
})

const slots = useSlots();

const page = usePage();
const user = computed(() => page.props.auth.user);
const previousUrl = computed(() => page.props.routing.previous_url);
const listing = computed(() => page.props.listing);

const sidebarOpen = ref(false)
const isOpen = ref(false);

const navigation = user.value ? [
    {
        name: 'Create Listing',
        href: route('listing.create'),
        icon: PlusIcon,
        current: route().current('listing.create'),
        mobileOnly: true,
    },
    {
        name: 'Home',
        href: route('index'),
        icon: HomeIcon,
        current: route().current('index')
    },
    {
        name: 'Profile',
        href: route('user.show', {user: page.props.auth.user.username}),
        icon: UserIcon,
        current: route().current('user.show', {
            user: page.props.auth.user.username
        }),
        desktopOnly: true,
    },
    {
        name: 'Settings',
        href: route('settings.edit'),
        icon: CogIcon,
        current: route().current('settings.edit')
    },
    // {
    //     name: 'About',
    //     href: route('about'),
    //     icon: LightBulbIcon,
    //     current: route().current('about'),
    //     mobile: false,
    // },
    // {
    //     name: 'Help',
    //     href: route('help'),
    //     icon: LifebuoyIcon,
    //     current: route().current('help'),
    //     mobile: false,
    // },
    {
        name: 'Sign Out',
        href: route('logout'),
        icon: ArrowLeftStartOnRectangleIcon,
        current: false,
        method: "POST",
        mobile: false,
    },
] : [
    {
        name: 'Home',
        href: route('index'),
        icon: HomeIcon,
        current: route().current('index')
    },
    // {
    //     name: 'About',
    //     href: route('about'),
    //     icon: LightBulbIcon,
    //     current: route().current('about')
    // },
    // {
    //     name: 'Help',
    //     href: route('help'),
    //     icon: LifebuoyIcon,
    //     current: route().current('help')
    // }
]
;

const openLogInModal = () => {
    isOpen.value = true;
};

const closeLogInModal = () => {
    isOpen.value = false;
};

const back = () => {
    if(previousUrl.value && previousUrl.value !== window.location.href) {
        window.history.back();
    } else {
        router.visit(route('index'));
    }
};
</script>
