export const addTag = (type, id) => {
    if (route().current('index') || route().current('listing.index')) {
        const params = {...route().params};

        if(params[type]?.length && !params[type].includes(id)) {
            params[type].push(id);
        } else {
            params[type] = [id];
        }

        return route('index', params);
    }

    return route('index', {
        [type]: [id]
    });
};
