<template>
<svg viewBox="0 0 240 150" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" >
    <g transform="matrix(1,0,0,1,-149.153,-216.108)">
        <g transform="matrix(168,0,0,168,144.161,348.9)">
            <path d="M0.538,-0L0.538,-0.261L0.295,-0.261L0.295,-0L0.074,-0L0.074,-0.688L0.295,-0.688L0.295,-0.437L0.538,-0.437L0.538,-0.688L0.759,-0.688L0.759,-0L0.538,-0Z" style="fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(168,0,0,168,266.465,348.9)">
            <path d="M0.363,-0.7C0.451,-0.7 0.523,-0.682 0.58,-0.647C0.637,-0.611 0.666,-0.558 0.667,-0.488L0.667,-0.476L0.46,-0.476L0.46,-0.48C0.46,-0.5 0.453,-0.517 0.438,-0.53C0.423,-0.543 0.401,-0.55 0.371,-0.55C0.342,-0.55 0.319,-0.546 0.304,-0.537C0.288,-0.528 0.28,-0.518 0.28,-0.505C0.28,-0.487 0.291,-0.474 0.312,-0.465C0.333,-0.456 0.368,-0.447 0.415,-0.438C0.47,-0.427 0.516,-0.415 0.552,-0.403C0.587,-0.39 0.618,-0.37 0.645,-0.342C0.672,-0.314 0.685,-0.276 0.686,-0.228C0.686,-0.147 0.659,-0.086 0.604,-0.047C0.549,-0.008 0.475,0.012 0.383,0.012C0.276,0.012 0.192,-0.006 0.133,-0.042C0.073,-0.078 0.043,-0.142 0.043,-0.233L0.252,-0.233C0.252,-0.198 0.261,-0.175 0.279,-0.164C0.297,-0.152 0.325,-0.146 0.363,-0.146C0.391,-0.146 0.414,-0.149 0.433,-0.155C0.451,-0.161 0.46,-0.173 0.46,-0.192C0.46,-0.209 0.45,-0.221 0.43,-0.23C0.409,-0.238 0.376,-0.247 0.33,-0.256C0.274,-0.268 0.228,-0.281 0.191,-0.294C0.154,-0.307 0.122,-0.328 0.095,-0.358C0.068,-0.388 0.054,-0.429 0.054,-0.48C0.054,-0.555 0.083,-0.611 0.142,-0.647C0.2,-0.682 0.274,-0.7 0.363,-0.7Z" style="fill-rule:nonzero;"/>
        </g>
    </g>
</svg>
</template>
